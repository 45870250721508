@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
  background-image: url('./assets/images/homeBg.png');
  /* 替换为你的图片链接 */
  background-size: cover;
  /* 背景图片覆盖整个页面 */
  background-position: center;
  /* 背景图片居中 */
  background-repeat: no-repeat;
  /* 禁止背景图片重复 */
  background: #0B0B0F;
  font-family: Inter;
  color: #ffffff;
  padding: 18px 60px 100px 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-light .ant-menu-item-selected {
  border-radius: 4px !important;
  background: #F46171 !important;
  color: #ffffff !important;
}

.ant-menu-item>.ant-menu-title-content {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  width: 220px !important;
  padding: 6px !important;
  border-radius: 4px !important;
}

.ant-menu-light .ant-menu-item {
  color: #ffffff !important;
}

.ant-input-outlined {
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.10) !important;
  background: rgba(255, 255, 255, 0.05) !important;
  color: #ffffff !important;
  height: 60px !important;
}

.ant-input-outlined:hover {
  background: #0B0B0F !important;
  color: #ffffff !important;
  border-color: #ffffff !important
}

.ant-input-outlined:focus {
  background: #0B0B0F !important;
  color: #ffffff !important;
  border-color: #ffffff !important
}

.ant-select-selector {
  background: #0B0B0F !important;
  color: #ffffff !important;
}

.ant-select-selector:hover {
  background: #0B0B0F !important;
  color: #ffffff !important;
  border-color: #ffffff !important
}

.ant-select-selector:focus {
  background: #0B0B0F !important;
  color: #ffffff !important;
  border-color: #ffffff !important
}

.ant-select .ant-select-arrow {
  color: #ffffff;
}

.ant-col {
  margin-right: 20px;
}

.ant-btn-primary {
  border-radius: 10px;
  border: 1px solid var(--gradient-light, rgba(255, 255, 255, 0.47));
  background: #F46171;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding: 22px 36px;
}

.ant-btn-primary:hover {
  border-radius: 10px;
  border: 1px solid var(--gradient-light, rgba(255, 255, 255, 0.47));
  background: #ef4b61 !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding: 22px 36px;
}

.ant-steps-item-title {
  color: #ffffff !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: rgba(255, 255, 255, 0.50) !important;
}

.ant-steps .ant-steps-item-tail::after {
  background-color: rgba(255, 255, 255, 0.50) !important;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #ef4b61 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #ef4b61 !important;
  border-color: #ef4b61 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #ef4b61 !important;
}

.ant-collapse-arrow svg {
  color: #fff;
}

.ant-card {
  background: #0B0B0F !important;
  color: #ffffff;
}

.ant-card .ant-card-head {
  color: #ffffff;
}

.ant-collapse .ant-collapse-content {
  color: #ffffff;
  background-color: #0B0B0F;
  border-top: 1px solid #d9d9d9;
}

.ant-dropdown-menu-item {
  color: #ffffff !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #0B0B0F !important;
  color: #ffffff !important;
}

.ant-dropdown-menu {
  background: #363333 !important;
  color: #ffffff;
}