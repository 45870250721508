/* 左侧导航固定宽度 */
#leftMenu {
    position: absolute;
    top: 120px;
    bottom: 100px;
    background: none;
}

/* logo样式 */
#logo {
    display: block;
    margin: 20px auto;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
}

#logo:hover {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}


/* 右侧宽度自适应 */
#rightWrap {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: 72px;
    position: absolute;
    top: 120px;
    left: 300px;
    right: 0;
    bottom: 0;
    overflow-y: auto;

}

.right-box {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px 20px 0;
    position: absolute;
    top: 73px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
}

#rightWrap .ant-menu-submenu {
    float: right;
}

.chart-box {
    margin-top: 30px;
}

.ege {
    display: inline-block;
    margin-top: 10px;
}

.ege a {
    color: #009688;
    font-size: 16px;
}

.lastPic {
    display: block;
    margin: 16% auto 0;
}

.ani-box {
    text-align: center;
}

.doclist {
    line-height: 40px;
}