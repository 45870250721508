.title {
    color: #F46171;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 16px;
}

.tips {
    color: #ffffff;
    padding-bottom: 30px;
    border-bottom: 1px solid #ffffff;
    width: 820px;
}

.viewAll {
    color: #ffffff;
    margin-top: 20px;
}

.other {
    margin-top: 20px;
}

.itemBox img {
    margin-right: 8px;
}

.itemBox {
    width: 240px;
    margin-bottom: 16px;
    margin-left: 4px;
    cursor: pointer;
}

.assets {
    display: flex;
    border-radius: 4px;
    border: 1px solid #FFF;
    padding: 4px 8px;
}

.address {
    font-size: 14px;
}

.number {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.switch {
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-top: 8px;
    cursor: pointer;
}

.deleteBtn {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: -15px;
    cursor: pointer;
    color: #FFF;
}

.btcAccount {
    width: 730px;
    height: 66px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.btcLeft {
    display: flex;
    align-items: center;
}

.btcRight {
    display: flex;
    align-items: center;
}

.btcRight img {
    margin-left: 16px;
    width: 19px;
    height: 10px;
}

.btcLeft img {
    margin-right: 16px;
}

.qrcode {
    width: 232px;
    height: 232px;
    border-radius: 10px;
    padding: 16px;
    background: #FFF;
    margin: 50px 0;
}

.deposits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.steps {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
}

.step {
    width: 370px;
    border: 2px solid rgba(255, 255, 255, 0.50);
    height: 4px;
    cursor: pointer;
}

.stepSelect {
    border: 3px solid #fff;
}

.inputAddress {
    color: rgba(255, 255, 255, 0.20);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

.utxoBox {
    display: flex;
    border-radius: 4px;
    border: 1px solid #FFF;
    padding: 4px 8px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.utxoSelect {
    background: #F46171;
    border: none;
}

.connectWalletBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    border: 1px solid #FFF;
    background: #000;
    padding: 4px 20px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
}