.title {
  color: #FFF;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}

.title img {
  margin-right: 10px;
}

.active {
  background: rgba(201, 178, 244, 0.3);
  border-radius: 16px 16px 16px 16px;
  padding: 16px;
}

.connectWalletBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: 1px solid #FFF;
  background: #000;
  padding: 10px 25px;
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.connect {
  display: flex;
  align-items: center;
  background: #363333;
  border-radius: 7px;
  padding: 4px 0 4px 10px;
  cursor: pointer;
}

.account {
  margin-right: 16px;
}